const all = document.querySelector('.section-slide').querySelectorAll('.image-holder')

all.forEach(elm => elm.addEventListener('click', ev => {
  ev.stopPropagation()
  all.forEach(t => t.classList.remove('show'))
  elm.classList.add('show')
}))

document.addEventListener('click', () => {
  all.forEach(t => t.classList.remove('show'))
})
